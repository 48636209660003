import TopNavbar from "../../widgets/navBar";
import norway_buildings from "../../../assets/images/backgrounds/norway_buildings.jpg";
import Container from "react-bootstrap/Container";
import {Card, Row, Tab, Tabs} from "react-bootstrap";
import {Helmet} from "react-helmet";
import IdeaToStorylineTab from "../../widgets/writing_tools_tabs/ideaToStorylineTab";
import CharacterMisbeliefTab from "../../widgets/writing_tools_tabs/characterMisbeliefTab";
import CharacterArcTab from "../../widgets/writing_tools_tabs/characterArcTab";
import PdfResources from "../../widgets/writing_tools_tabs/pdfResources";

function WritingTools() {

    return (
        <div className="App" style={{ backgroundImage: `url(${norway_buildings})` }}>
            <Helmet>
                <title>TSBudd | Writing Tools</title>
            </Helmet>
            <div className="App-navbar">
                <TopNavbar/>
            </div>

            <Container className="App-body">
                <Row>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(255,255,255,0.8)"}}>
                        <Card.Body>
                            <Card.Title>Writing Tools</Card.Title>
                            <Tabs defaultActiveKey="ideaToStoryline" id="uncontrolled-tab-example" className="Tabs">
                                <Tab eventKey="ideaToStoryline" title="Idea To Storyline" className="Tab-single">
                                    {IdeaToStorylineTab()}
                                </Tab>
                                <Tab eventKey="characterMisbelief" title="Character Misbelief" className="Tab-single">
                                    {CharacterMisbeliefTab()}
                                </Tab>
                                <Tab eventKey="characterArc" title="Character Arc Formula" className="Tab-single">
                                    {CharacterArcTab()}
                                </Tab>
                                <Tab eventKey="otherResources" title="Other Resources" className="Tab-single">
                                    {PdfResources()}
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div>
    );
}

export default WritingTools;