import {useState} from "react";
import TopNavbar from "../widgets/navBar";
import norway_fjord from "../../assets/images/backgrounds/norway_fjord.jpg"
import Container from "react-bootstrap/Container";
import {Button, Card, Col, Image, Modal, Row} from "react-bootstrap";
import me_jlg from "../../assets/images/me_jlg.jpg";
import {FloatLabel} from "primereact/floatlabel";
import {Panel} from "primereact/panel";
import {Helmet} from "react-helmet";
import React from 'react';
import resumePDF from "../../assets/pdf/RESUME_JAN_2025(NO_QR_Code).pdf";

function Contact() {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="App" style={{ backgroundImage: `url(${norway_fjord})` }}>
            <Helmet>
                <title>TSBudd | Contact</title>
            </Helmet>
            <div className="App-navbar">
                <TopNavbar/>
            </div>

            <Container className="App-body">
                <Row>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(255,255,255,0.8)"}}>
                        <Card.Body>
                            <Card.Title><h3>Contact Me</h3></Card.Title>

                            <Container>
                                <Row style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Col lg={4} >
                                        <Image src={me_jlg} fluid style={{width: "300px", height: "auto"}}/>
                                    </Col>
                                    <Col lg={8}>
                                        <br/>
                                        <Row>
                                            <FloatLabel>
                                                Feel free to contact me in regards of potential career opportunities, or
                                                just for the sake of contacting me. Shoot me an email or DM/PM and I will
                                                reply as soon as I can.
                                            </FloatLabel>
                                        </Row>
                                        <br/>

                                        <Row>
                                            <Col>
                                                <a href="mailto:t.s.budd0@gmail.com?subject=[tsbudd.com]_contact_me">
                                                    <i className="pi pi-at" style={{ fontSize: '4rem' }}></i>
                                                </a>
                                                <p>Email</p>
                                            </Col>
                                            <Col>
                                                <a href="https://www.linkedin.com/in/ts-budd/" target="_blank">
                                                    <i className="pi pi-linkedin" style={{ fontSize: '4rem' }}></i>
                                                </a>
                                                <p>LinkedIn</p>
                                            </Col>
                                            <Col>
                                                <a href="https://www.facebook.com/tyler.budd.75" target="_blank">
                                                    <i className="pi pi-facebook" style={{ fontSize: '4rem' }}></i>
                                                </a>
                                                <p>Facebook</p>
                                            </Col>
                                            <Col>
                                                <a href="https://www.instagram.com/t.s.budd/" target="_blank">
                                                    <i className="pi pi-instagram" style={{ fontSize: '4rem' }}></i>
                                                </a>
                                                <p>Instagram</p>
                                            </Col>
                                            <Col>
                                                <a href="https://github.com/tsbudd" target="_blank">
                                                    <i className="pi pi-github" style={{ fontSize: '4rem' }}></i>
                                                </a>
                                                <p>GitHub</p>
                                            </Col>
                                            {/*<Col>*/}
                                            {/*    <a href="https://github.com/tsbudd" target="_blank">*/}
                                            {/*        <i className="fa-brands fa-spotify" style={{ fontSize: '4rem' }}></i>*/}
                                            {/*    </a>*/}
                                            {/*    <p>Spotify</p>*/}
                                            {/*</Col>*/}
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Button variant="success" onClick={() => {setShowModal(true)}}>
                                                See Resume
                                            </Button>

                                            <Modal show={showModal} onHide={() => {setShowModal(false)}} size={"xl"}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Resume</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <iframe
                                                        src={resumePDF}
                                                        style={{ width: '100%', height: '80vh'}}
                                                    ></iframe>
                                                </Modal.Body>
                                            </Modal>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>
                <br/>
                <Row>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(255,255,255,0)"}}>
                        <Card.Body>
                            <Row>
                                <Col lg={6}>
                                    <Panel header="Technical Skillset">
                                        <div style={{textAlign: "left"}}>
                                            <p><b>Programming/Scripting Languages:</b></p>
                                            <ul>
                                                <li>Python</li>
                                                <li>C/C++</li>
                                                <li>Java</li>
                                                <li>Javascript/Typescript</li>
                                                <li>MySQL/Postgres</li>
                                                <li>Dart</li>
                                            </ul>

                                            <p><b>Operating Systems:</b></p>
                                            <ul>
                                                <li>MacOSX</li>
                                                <li>Windows 10/11</li>
                                                <li>Debian/Ubuntu Linux</li>
                                                <li>TrueNas Scale</li>
                                            </ul>

                                            <p><b>Development Tools:</b></p>
                                            <ul>
                                                <li>JetBrains-based (WebStorm, IntelliJ, PyCharm, Android Studio)</li>
                                                <li>VSCode</li>
                                                <li>Postman</li>
                                                <li>ThingWorx by PTC</li>
                                                <li>Atlassian JIRA/Confluence</li>
                                                <li>Docker Desktop</li>
                                                <li>MS SQL Server Workbench</li>
                                                <li>MySQL Workbench</li>
                                                <li>Lens Kubernetes IDE</li>
                                            </ul>

                                            <p><b>Database Engines:</b></p>
                                            <ul>
                                                <li>MariaDb</li>
                                                <li>Postgres</li>
                                                <li>ADX/MSSQL</li>
                                            </ul>

                                            <p><b>Focused Practices:</b></p>
                                            <ul>
                                                <li>Web/App Development</li>
                                                <li>REST API Development</li>
                                                <li>IoT (Internet of Things)</li>
                                                <li>Data-heavy Dashboard UI Design and Implementation</li>
                                                <li>Docker/Kubernetes Dev-Ops</li>
                                            </ul>

                                            <p><b>Frameworks:</b></p>
                                            <ul>
                                                <li>Django REST</li>
                                                <li>Quarkus REST</li>
                                                <li>React (React Redux, React Native, Ionic React)</li>
                                                <li>Flutter</li>
                                            </ul>
                                        </div>
                                    </Panel>
                                </Col>
                                <Col lg={6}>
                                    <Panel header={"Work Experience"}>
                                        <div style={{textAlign: "left"}}>
                                            <h5><p><b><a href={"https://www.jlg.com/en"} target="_blank">JLG Industries:</a></b> (Oshkosh Corp Subsidiary)</p></h5>
                                            <h6><p>Software Engineer I: (May 2023 - present)</p></h6>
                                            <h6><p>Software Engineering Intern: (May 2022 - May 2023)</p></h6>
                                            <ul>
                                                <li>
                                                    Initiated, developed, and supervised an internal diagnostic
                                                    dashboard project that utilized a custom modular Ionic React
                                                    structure to monitor and troubleshoot issues concerning corporate
                                                    and employee assets.
                                                </li>
                                                <li>
                                                    Designed, tested, and documented multiple REST API endpoints, each
                                                    facilitating the retrieval of machine data or initiating IoT
                                                    cloud-to-device commands using Red Hat Quarkus.
                                                </li>
                                                <li>
                                                    Fostered and maintained collaborative relationships across
                                                    departments to gather ongoing stakeholder feedback to tailor
                                                    experiences that met the specific needs of said stakeholders.
                                                </li>
                                                <li>
                                                    Provided guidance and mentorship to interns and new team members,
                                                    including coaching on industry and company software development
                                                    practices through pair programming sessions and leading weekly
                                                    project meetings.
                                                </li>
                                            </ul>

                                            <br/>

                                            <h5><p><b><a href={"https://www.michigantechrecreation.com/oap/index"} target="_blank">Outdoor Adventure Program:</a></b> (Michigan Tech Athletics Department)</p></h5>
                                            <h6><p>Front Desk Staff: (September 2020 - November 2022)</p></h6>
                                            <h6><p>Indoor Climbing Class Instructor: (January 2022 - May 2022)</p></h6>
                                            <h6><p>Kayak Class Instructor: (August 2021 - October 2021)</p></h6>
                                            <ul>
                                                <li>Coordinated and supervised the execution of the program’s
                                                    effectiveness by hiring, training, and scheduling staff to make the
                                                    rental process for our customers effective and efficient.</li>
                                                <li>Acted as a teaching assistant in the Introduction to Kayaking class
                                                    and Introduction to Climbing class, where I instructed and led
                                                    students to kayak and climb safely to enjoy outdoor activities.</li>
                                            </ul>
                                        </div>
                                    </Panel>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div>
    );
}

export default Contact;