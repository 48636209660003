import {useDispatch, useSelector} from "react-redux";
import {fetchTemplateData} from "../../store/slices/templateSlice";
import {useEffect, useState} from "react";
import TopNavbar from "../widgets/navBar";
import me_norway2 from "../../assets/images/home_page/me_norway2.JPG"
import me_keweenaw1 from "../../assets/images/home_page/me_keweenaw1.jpg"
import workout_collage from "../../assets/images/home_page/workout_collage.jpeg"
import {Carousel} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Sidebar} from "primereact/sidebar";

import "primereact/resources/themes/viva-dark/theme.css";

function Home() {
    const dispatch = useDispatch();
    const fact = useSelector((state) => state.data.fact.data);
    const [showFact, setShowFact] = useState(false);

    useEffect(() => {
        dispatch(fetchTemplateData())
    }, [dispatch]);

    return (
        <div className="App">
            <Helmet>
                <title>TSBudd | Home</title>
            </Helmet>
            <div className="App-navbar">
                <TopNavbar/>
            </div>

            <div className="random-fact-button" onClick={() => setShowFact(true)}>
                Want a Random Fact?
            </div>

            <Sidebar visible={showFact} position="right" onHide={() => setShowFact(false)}>
                <h4>Did You Know?</h4>
                <br/>
                <p>{fact}</p>
            </Sidebar>

            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100 carousel-image"
                        src={me_keweenaw1}
                        alt="First slide"
                    />
                    <Carousel.Caption className="custom-caption">
                        <h2 style={{fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace"}}>Welcome!</h2>
                        <p>
                            <span className="typing-effect">My name is Tyler S. Budd</span>
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 carousel-image"
                        src={me_norway2}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 carousel-image"
                        src={workout_collage}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default Home;