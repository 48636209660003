import TopNavbar from "../widgets/navBar";
import bridge from "../../assets/images/houghton_bridge.jpg";
import Container from "react-bootstrap/Container";
import {Button, Card, Col, Image, Row} from "react-bootstrap";
import {DataTable} from "primereact/datatable";
import educationJson from "../../assets/json/education.json";
import {Column} from "primereact/column";
import {FloatLabel} from "primereact/floatlabel";
import mtuLogo from "../../assets/images/mtu_logo_small.png";
import houghtonEvening from "../../assets/images/backgrounds/houghton_evening.jpg";
import meGraduation from "../../assets/images/me_graduation.jpg";
import {Helmet} from "react-helmet";

function Education() {
    const educationList = educationJson.mtu;

    return (
        <div className="App" style={{ backgroundImage: `url(${houghtonEvening})` }}>
            <Helmet>
                <title>TSBudd | Education</title>
            </Helmet>
            <div className="App-navbar">
                <TopNavbar/>
            </div>

            <Container className="App-body">
                <Row>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(255,255,255,0.8)"}}>
                        <Card.Body>
                            <Card.Title><h2>Education at Michigan Technological University</h2></Card.Title>

                            <Container style={{borderRadius: "15px", backgroundColor: "rgba(255,255,255,0.8)",
                                padding: "25px", paddingBottom: "15px"}}>
                                <Row>
                                    <Col lg={3} >
                                        <Row style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Image src={mtuLogo} fluid style={{width: "250px", height: "auto"}}/>
                                        </Row>
                                        <br/>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <Row>
                                                <FloatLabel>
                                                    <h6><u>Degree:</u> Software Engineering</h6>
                                                </FloatLabel>
                                            </Row>
                                            <Row>
                                                <FloatLabel>
                                                    <h6><u>Graduating GPA:</u> 3.00</h6>
                                                </FloatLabel>
                                            </Row>
                                            <Row>
                                                <FloatLabel>
                                                    <h6><u>Graduation Date:</u> April 2023</h6>
                                                </FloatLabel>
                                            </Row>
                                            <br/>
                                        </div>

                                    </Col>
                                    <Col lg={9}>
                                        <Row>
                                            <FloatLabel>
                                                I began studying at MTU in the fall of 2019. I switched my major from
                                                Mechanical Engineering to Software Engineering in my second semester,
                                                and knew I made the right decision right away. After living in the second
                                                snowiest town in the US for over four years, I graduated and started my
                                                professional career with Oshkosh Corporation.
                                            </FloatLabel>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <h5>Involvement</h5>
                                            <FloatLabel>
                                                On campus, I founded the Creative Writing Club at MTU where students meet to
                                                discuss and work on fiction, poetry, and screenwriting pieces. It is
                                                still active today, three years after its conception. I also worked for
                                                Michigan Tech's Outdoor Adventure Program, which allowed me to teach
                                                kayaking and rock climbing classes, among other things.
                                            </FloatLabel>
                                        </Row>
                                        <br/>
                                        <Button href="https://www.mtu.edu/cs/undergraduate/software/"
                                                type="submit" variant={"warning"} target="_blank">
                                            About Software Engineering at Michigan Tech
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>
                <br/>
                <Row>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(255,255,255,0.8)"}}>
                        <Container style={{padding: "15px"}}>
                            <Row>
                                <Col lg={8}>
                                    <Image src={bridge} style={{maxHeight: "400px", width: "100%"}}/>
                                </Col>
                                <Col lg={4}>
                                    <Image src={meGraduation} style={{height: "400px", width: "auto"}}/>
                                </Col>
                            </Row>
                        </Container>
                    </Card>
                </Row>
                <br/>
                <Row>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(255,255,255,0.8)"}}>
                        <Card.Body>
                            <Card.Title>Engineering Classes Taken For Degree</Card.Title>
                            <Container style={{padding: "15px"}}>
                                <DataTable value={educationList} paginator rows={10} size={"small"}
                                           stripedRows>
                                    <Column field={"className"} header={"Class"}/>
                                    <Column field={"code"} header={"Code"}/>
                                    <Column field={"year"} header={"Year"}/>
                                    <Column field={"semester"} header={"Semester"}/>
                                </DataTable>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div>
    );
}

export default Education;