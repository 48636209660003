import Container from "react-bootstrap/Container";
import {Carousel, Col, Image, Row} from "react-bootstrap";
import {Panel} from "primereact/panel";
import pennypusherIcon from "../../../assets/images/pennypusher_icon.PNG";
import two from "../../../assets/images/pennypusher_screenshots/2.png";
import three from "../../../assets/images/pennypusher_screenshots/3.png";
import four from "../../../assets/images/pennypusher_screenshots/4.png";
import five from "../../../assets/images/pennypusher_screenshots/5.png";
import six from "../../../assets/images/pennypusher_screenshots/6.png";
import seven from "../../../assets/images/pennypusher_screenshots/7.png";

export default function pennypusherTab(){
    const carouselItems = [
        {
            "image": two,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": three,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": four,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": five,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": six,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": seven,
            "label": "test",
            "description": "asdf"
        },
    ]

    return(
        <Container>
            <Row>
                <Col>
                    <Panel header={"Introduction"}>
                        <Row>
                            <Col>
                                <p>
                                    PennyPusher has been my passion project since 2020. It has undergone many iterations, each
                                    more stable and scalable than the last. As I learned more about software engineering, I
                                    continuously improved it with my new knowledge and skillset. Oftentimes, I would start
                                    from scratch and refine my approach. What started as a simple python script has evolved into a
                                    multi-repository project, with the finished product deployed on multiple servers.
                                </p>
                                <br/>
                                <p>
                                    So what is PennyPusher?
                                </p>
                                <br/>
                                <p>
                                    It is a smart self-hosted personal budgeting app.
                                </p>
                            </Col>
                            <Col sm={3}>
                                <Image src={pennypusherIcon} height={200}/>
                            </Col>
                        </Row>

                    </Panel>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Panel header="Self-Hosted?">
                        <p>
                            The "Self-Hosted" approach means that the data you use is yours to own. Most apps you use
                            (Facebook, Adobe Photoshop, Gmail, etc.) store all of the data on their servers. This means
                            that somewhere, the photo of you and your dog on the beach is stored on a hard drive
                            halfway around the world. And it's not just one hard drive—it's multiple, creating
                            redundancy in case a hard drive fails. That is how data is typically stored on their
                            servers. The truth is, this is a good solution for data that is meant to be shared.
                        </p>

                        <br/>

                        <p>
                            But what happens when we don't want to share our financial data? What if we want to keep
                            it private and secure?
                        </p>

                        <p>Enter PennyPusher</p>

                        <br/>

                        <p>
                            What many people DON'T know is that you can create a simple, low-energy server at home
                            that acts as your "cloud." PennyPusher is designed so <b>YOUR DATA IS YOURS</b>, not
                            Zuckerberg's. The data you create and use is only available to you and those you choose
                            to share it with.
                        </p>

                    </Panel>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Panel header="How It Works">
                        <p>
                            Every app that uses the internet has two parts: a server and a client.
                        </p>

                        <p>
                            The server is in charge of all the databases, security, calculations, etc., and it acts as
                            our "cloud." Technically, this is all we really need. However, it wouldn't be easy to use,
                            and it would be difficult to read a lot of JSON text and understand everything.
                        </p>

                        <p>
                            The client's job is to create a user interface for the data we pull from our server and
                            present it at our fingertips. It's the app we use on our browser, phone, or tablet.
                            In essence, the client is just a really fancy translator, turning raw, unreadable data
                            into 'pretty' widgets and buttons.
                        </p>

                        <p>
                            PennyPusher has two main parts. The 'server' can be installed and run on our home machines,
                            while the 'client' is an app we can install on our phones or access via our web browser.
                        </p>

                    </Panel>
                </Col>
                <Col>
                    <Panel header="Main Features">
                        <p>PennyPusher tracks the following:</p>
                        <div style={{ textAlign: "center" }}> {/* Center align parent */}
                            <ul style={{ display: "inline-block", textAlign: "left" }}> {/* Center the list */}
                                <li>Budgets</li>
                                <li>Funds</li>
                                <li>Accounts</li>
                                <li>Loans</li>
                                <li>Expenses</li>
                                <li>Income <i>(non-paychecks)</i></li>
                                <li>Paychecks</li>
                                <li>Bills</li>
                                <li>Subscriptions</li>
                                <li>Desired Purchases</li>
                                <li>Items For Sale</li>
                            </ul>
                        </div>
                    </Panel>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Panel header="Project Specifications (Server)">
                        <div style={{textAlign: "left"}}>
                            <h6><u>Platform:</u> Django</h6>
                            <h6><u>Programming Language:</u> Python</h6>
                            <h6><u>IDE:</u> PyCharm by JetBrains</h6>
                            <h6><u>Recommended Deployment Method:</u> Kubernetes Multi-pod Cluster of Docker Container</h6>

                            <h6><u>Notable Practices used:</u></h6>
                            <ul>
                                <li>Enabled Bearer Token Authorization (24hr access token lifecycles)</li>
                                <li>Enabled CSRF Precautions</li>
                                <li>Modified CORS Specifications</li>
                                <li>Extra layer of database encryption for sensitive information (e.g. account numbers)</li>
                                <li>Rate limiting (to fight against DDOS attacks) </li>
                                <li>Wrote API documentation (powered by OpenAPI 3)</li>
                            </ul>
                        </div>
                    </Panel>
                </Col>
                <Col>
                    <Panel header="Project Specifications (Client)">
                        <div style={{textAlign: "left"}}>
                            <Row>
                                <Col lg={9}>
                                    <h6><u>Platform:</u> Flutter</h6>
                                    <h6><u>Programming Language:</u> Dart</h6>
                                    <h6><u>IDE:</u> Android Studio</h6>
                                    <h6><u>Optimized Platforms:</u></h6>
                                    <ul>
                                        <li>Android OS - Android API 34+</li>
                                        <li>Web</li>
                                    </ul>

                                    <h6><u>Notable Packages used:</u></h6>
                                    <ul>
                                        <li><a href={"https://pub.dev/packages/riverpod"} target="_blank">riverpod</a> - for state management</li>
                                        <li><a href={"https://pub.dev/packages/protobuf"} target="_blank">protobuf</a> - for easier data mapping</li>
                                        <li><a href={"https://pub.dev/packages/go_router"} target="_blank">go_router</a> - for app navigation</li>
                                        <li><a href={"https://pub.dev/packages/local_auth"} target="_blank">local_auth</a> - for bio-authentication (mobile only)</li>
                                        <li><a href={"https://pub.dev/packages/secure_storage"} target="_blank">secure_storage</a> - for memory storage</li>
                                    </ul>
                                </Col>
                            </Row>

                        </div>
                    </Panel>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Panel header="Screenshots">
                        <div style={{backgroundColor: 'rgba(0,0,0,.6)'}}>
                            <Carousel fade>
                                {carouselItems.map((item, index) => (
                                    <Carousel.Item key={index}>
                                        <Image src={item.image}
                                               fluid style={{width: "auto", height: "800px"}}/>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>

                    </Panel>
                </Col>
                <Col>
                    <Panel header="Try It Yourself!">
                        <p>
                            <a href={"https://app-t.pennypusher.net"} target="_blank">Try PennyPusher</a>
                        </p>
                        <br/>
                        <p>Server URL: <b>test.pennypusher.net</b></p>
                        <p>Username: <b>demoUser</b></p>
                        <p>Password: <b>pennypusherDemo</b></p>
                        <br/>
                        <p><b>WARNING: DO NOT USE ANY SENSITIVE/REAL DATA</b></p>
                        <p><i>Note: service is occasionally unavailable due to maintenance</i></p>
                    </Panel>
                </Col>
            </Row>
        </Container>
    )
}