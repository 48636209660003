import TopNavbar from "../widgets/navBar";
import norway_buildings from "../../assets/images/backgrounds/norway_buildings.jpg";
import Container from "react-bootstrap/Container";
import {Card, Row, Tab, Tabs} from "react-bootstrap";
import VigenereTab from "../widgets/github_tabs/vigenereTab";
import pennypusherTab from "../widgets/github_tabs/pennypusherTab";
import MobileToolboxTab from "../widgets/github_tabs/mobileToolboxTab";
import {Helmet} from "react-helmet";

function Projects() {

    return (
        <div className="App" style={{ backgroundImage: `url(${norway_buildings})` }}>
            <Helmet>
                <title>TSBudd | Projects</title>
            </Helmet>
            <div className="App-navbar">
                <TopNavbar/>
            </div>

            <Container className="App-body">
                <Row>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(255,255,255,0.8)"}}>
                        <Card.Body>
                            <Card.Title>Recent Projects</Card.Title>
                            <Tabs defaultActiveKey="pennypusher" id="uncontrolled-tab-example" className="Tabs">
                                <Tab eventKey="pennypusher" title="PennyPusher" className="Tab-single">
                                    {pennypusherTab()}
                                </Tab>
                                <Tab eventKey="toolbox" title="Mobile Toolbox" className="Tab-single">
                                    {MobileToolboxTab()}
                                </Tab>
                                <Tab eventKey="vigenere" title="Advanced Vigenère Cypher" className="Tab-single">
                                    {VigenereTab()}
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div>
    );
}

export default Projects;